.cellAction {
    display: flex;
    align-items: center;
    gap: 15px;
    a{
      text-decoration: none;
    }

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      border: 1px dotted ;
      cursor: pointer;

    }
    
    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }


