:root{
    --orange:#ff7800;
    --black:#130f40;
    --light-color:#666;
    --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
    --border:.2rem solid rgba(0,0,0,.1);
    --outline:.1rem solid rgba(0,0,0,.1);
    --outline-hover:.2rem solid var(--black);
}
section{
  padding:2rem 9%;
  margin: 2rem;
}

.heading{
  text-align: center;
  padding:2rem 0;
  padding-bottom: 3rem;
  font-size: 3.5rem;
  color:var(--black);
}

.heading span{
  background: var(--orange);
  color:#fff;
  display: inline-block;
  padding:.5rem 3rem;
  clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
}

.btn{
  margin-top: 1rem;
  display: inline-block;
  padding:.8rem 3rem;
  font-size: 1.7rem;
  border-radius: .5rem;
  border:.2rem solid var(--black);
  color:var(--black);
  cursor: pointer;
  background: none;
}

.btn:hover{
  background: var(--orange);
  color:#fff;
}

.blogs .box-container{
display: flex;
align-items: center;
justify-content:space-between;
margin : 2rem auto;
flex-wrap: wrap;
}


.blogs .box-container .box{
  overflow: hidden;
  position: relative;
  border-radius: .5rem;
  margin-top: 1.5rem;
  max-width: 300px;
  box-shadow: var(--box-shadow);
  background:#fff;
}

.blogs .box-container .box img{
  height:13rem;
  width:100%;
  object-fit: cover;
}
.blogs .box-container .box span{
  position: absolute;
  right:  5%;
  top:3%;
  z-index: 10000;
  cursor: pointer;
  
}
.blogs .box-container .box span svg {
  color: rgb(197, 11, 11);
  transition: .5;
  font-size: 1.3rem;
  transform: translate(-3px);

}
.blogs .box-container .box span:hover svg {
  transform: scale(2) rotate(20deg) 

}


.blogs .box-container .box .content{
  padding:.5rem;
}

.blogs .box-container .box .content .icons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a{
  color:var(--light-color);
  font-size: 1rem;
  text-decoration: none;
}

.blogs .box-container .box .content .icons a:hover{
  color:var(--black);
}

.blogs .box-container .box .content .icons a i{
  color:var(--orange);
  padding-right: .5rem;
}

.blogs .box-container .box .content h3{
  line-height: 1;
  color:var(--black);
  font-size: 1.5rem;
  padding:.5rem 0;
  text-align: center;
}

.blogs .box-container .box .content p{
  line-height: 1.8;
  color:var(--light-color);
  font-size: 1rem;
  padding:.5rem 0;
}
